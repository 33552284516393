.info-window-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gm-style-iw-chr h2 {
  font-size: 16px;
  margin: 0;
  flex-grow: 1;
  text-align: left;
}

.gm-style-iw-chr button {
  flex-shrink: 0;
}
