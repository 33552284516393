@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family:
    'Helvetica Neue',
    Helvetica,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f4f6;
}

/* Swiper component */
.swiper-button-next,
.swiper-button-prev {
  color: #000;
  width: 20px;
  height: 20px;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 1.5rem;
}

/* Map InfoWindowF */
.gm-style-iw-chr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 6px;
}

.gm-style-iw-chr h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  flex-grow: 1;
  text-align: left;
}

.gm-style-iw-chr img {
  width: 70px;
  height: 60px;
  margin-right: 10px;
}

.info-window-content {
  width: 260px;
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 6; /* Number of lines to show */
  -webkit-line-clamp: 6; /* Number of lines to show */
  max-height: calc(1em * 6); /* 1em * number of lines */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.truncate-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* TailwindCSS doesn't directly support these specific fixed sizes out of the box.
   You'd typically add these in your global CSS file, or use inline styles. */

.stage-container {
  /* Default mobile size */
  width: 300px;
  height: 200px;
}

.height-minus-120 {
  height: calc(100vh - 120px);
}

@media (min-width: 640px) {
  /* Tailwind's 'sm' breakpoint */
  .stage-container {
    width: 400px;
    height: 266px;
  }
}

@media (min-width: 768px) {
  /* Tailwind's 'md' breakpoint */
  .stage-container {
    width: 600px;
    height: 400px;
  }
}

@media (min-width: 1024px) {
  /* Tailwind's 'lg' breakpoint */
  .stage-container {
    width: 800px;
    height: 533px;
  }
}

@media (min-width: 1280px) {
  /* Tailwind's 'xl' breakpoint */
  .stage-container {
    width: 1000px;
    height: 666px;
  }
}
