/* Style for the slider track (the line) */
.slider-track {
  background-color: #3b3838;
  height: 4px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 2px;
}

/* Style for the slider thumb (the ball) */
.slider-thumb {
  height: 16px;
  width: 16px;
  background: #ffbd59;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #3b3838;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  position: relative;
  top: -8px;
}
